import { Center, Loader } from "@mantine/core"
import { useUser } from "@soar/frontend/contexts"
import { PermissionNameEnum } from "@soar/shared/types"
import { useRouter } from "next/router"
import { useEffect } from "react"
import ProtectedPage from "../components/ProtectedPage"

function IndexPage() {
  const { user, refetch, organizations, isLoaded, hasPermissionForOrganization, isOrgDataLoaded } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (user == null || !isLoaded || !isOrgDataLoaded) {
      return
    }

    const canViewDashboardOrganization = organizations.find((organization) => {
      return hasPermissionForOrganization(organization.organization.id, PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD)
    })

    if (canViewDashboardOrganization != null) {
      router.push(`/organization/${canViewDashboardOrganization.organization.id}/dashboard`)
    } else {
      router.push("/home")
    }
  }, [isLoaded, organizations, hasPermissionForOrganization, user])

  return (
    <ProtectedPage profileType="user">
      <Center mt="xl">
        <Loader size="xl" variant="bars" />
      </Center>
    </ProtectedPage>
  )
}

export default IndexPage
