import { Anchor, Box, Flex, Text } from "@mantine/core"
import Link from "next/link"

export function AppFooter() {
  const year = new Date().getFullYear()
  return (
    <Box c="dark" p="xl" fz="sm">
      <Flex align="flex-end" direction="column">
        <Text>&copy; SoarUp, Inc. {year}</Text>
        <Flex gap="xs">
          <Link href="/privacy-policy" legacyBehavior passHref>
            <Anchor c="dark">Privacy</Anchor>
          </Link>
          <Link href="/terms-of-service" legacyBehavior passHref>
            <Anchor c="dark">Terms</Anchor>
          </Link>
        </Flex>
      </Flex>
    </Box>
  )
}
